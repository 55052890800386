import * as React from "react"
import Layout from "../components/public/layout"
import Newspanel from "../components/public/newsPanel"
import { Helmet } from "react-helmet"
const NotFoundPage = () => {
	return (
		<Layout>
			<Helmet title={`Parafia w Starych Skoszewach`}/>
			<div className="container">
				<div className="left-col">
					<div className="article-without-photo">
						<h3>Strony nie znaleziono!</h3>
						<br />
						Wybrany adres jest niepoprawny.
					</div>
				</div>
				<div className="right-col">
					<Newspanel />
					<div className="facebook-panel"></div>
				</div>
			</div>


		</Layout>
	)
}

export default NotFoundPage
